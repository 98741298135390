import { logos, icequbeProducts } from "../../images/index";

export const coolingSolutions = {
  carousel: {
    header: "Oblicz swoją klimatyzację z ",
    span: "IceQube",
    text: "Systemy wentylatorów obudów, klimatyzatory termoelektryczne, wymienniki ciepła, klimatyzatory do miejsc niebezpiecznych, wymienniki ciepła do miejsc niebezpiecznych, chłodzenie kontenerów/serwerowni.",
    button: "ODWIEDŹ STRONĘ I OBLICZ SWÓJ PROJEKT JUŻ DZIŚ",
    linkTo: "https://www.iceqube.com/technical/calculator/",
    images: [icequbeProducts.GroupPicture, icequbeProducts.evSeries],
  },
  manualsSection: {
    title:
      "Znajdź potrzebny Ci podręcznik i upewnij się, że Twoje systemy działają płynnie i bezpiecznie",
    subtitle: "Podręczniki",
    description:
      "Znajdź potrzebny Ci podręcznik i upewnij się, że Twoje systemy działają płynnie i bezpiecznie",
    btnNames: ["Seria EV", "Seria EVZ2", "Seria EX"],

    downloads: {
      EV: [
        {
          series: "Seria Evolution",
          imgLogo: logos.evLogo,
          text: "Seria EV od Ice Qube na nowo definiuje standardy branżowe zaawansowaną technologią, oferując klimatyzatory z certyfikatem CE, charakteryzujące się zwiększoną efektywnością, trwałością i wysoką temperaturą pracy 60*C (140*F). Przeznaczone do zwykłych lokalizacji.",
          name: "Klimatyzatory do obszarów bezpiecznych",
          url: "https://www.iceqube.com/manuals/QD-ENG-69%20Operation%20and%20Installation%20Manual%20-%20Evolution%20Series%20Rev%203.pdf",
        },
        {
          name: "Szybki przewodnik programowania klimatyzatorów obudów",
          url: "https://www.iceqube.com/manuals/Quick%20Programming%20Guide%20-%20May%202019.pdf",
        },
        {
          name: "Kontroler LCD Ethernet",
          url: "https://www.iceqube.com/manuals/QD-ENG-16%20Operation%20and%20Installation%20Manual%20-%20Ethernet.REV2.pdf",
        },
      ],
      EVZ2: [
        {
          series: "Seria Evolution Strefa 2",
          imgLogo: logos.evz2Logo,
          text: "Seria Evolution od Ice Qube wprowadza zaawansowane rozwiązania chłodzenia dla miejsc niebezpiecznych Strefy 2 z funkcjami takimi jak technologia przepływu powietrza w panelu pomocniczym, technologia stanu stałego i wysokie temperatury pracy, rewolucjonizując efektywność i trwałość w branży.",
          name: "Klimatyzatory Strefy 2",
          url: "https://www.iceqube.com/manuals/QD-ENG-71%20Operation%20and%20Installation%20Manual%20-%20Zone%202%20Evolution%20series%20ATEX%20IECEx%20REV7.pdf",
        },
        {
          name: "Rozwiązywanie problemów",
          url: "https://www.iceqube.com/manuals/troubleshooting.pdf",
        },
        {
          name: "Szybki przewodnik programowania klimatyzatorów obudów",
          url: "https://www.iceqube.com/manuals/Quick%20Programming%20Guide%20-%20May%202019.pdf",
        },
      ],

      EX: [
        {
          series: "Seria Ex Strefa 1",
          imgLogo: logos.exz1Logo,
          text: "Nowe klimatyzatory serii EX są zaprojektowane specjalnie dla środowisk niebezpiecznych, systemów obudów z przeczyszczaniem lub bez, i są dostarczane w obudowie ze stali nierdzewnej 316. Klimatyzatory Ice Qube Surface Vertical Mount Zone 1 - ATEX/IECEx/UK CA do miejsc niebezpiecznych oferują zakres mocy chłodzenia 1172-3517 Watt (4000-12000 Btu/h).",
          name: "Klimatyzatory Strefy 1",
          url: "https://www.iceqube.com/manuals/QD-ENG-74%20Operation%20and%20Installation%20Manual%20-%20Zone%201%20EX%20series%20ATEX%20IECEx%20Rev%201.pdf",
        },
        {
          name: "Rozwiązywanie problemów",
          url: "https://www.iceqube.com/manuals/troubleshooting.pdf",
        },
      ],
    },
  },
  certificatesSection: {
    title: "Zawsze aktualne i dostępne",
    subtitle: "Certyfikaty",
    description:
      "Każdy certyfikat jest starannie przedstawiony, aby zapewnić łatwy dostęp do szczegółowych informacji. Możesz przeglądać, pobierać i używać tych dokumentów, aby zweryfikować specyfikacje i zgodność naszych produktów, co jest niezbędne w regulowanych branżach wymagających dokładnej dokumentacji.",
    btnNames: ["Seria EV", "Seria EVZ2", "Seria EX"],
    atex: "Związane z certyfikatami związanymi z dyrektywą ATEX, która jest standardem Unii Europejskiej dla sprzętu przeznaczonego do użytku w potencjalnie wybuchowych atmosferach.",
    iecx: "Skupia się na globalnym standardzie IECEX dla sprzętu używanego w wybuchowych atmosferach.",
    eu: "Obejmuje ogólne deklaracje zgodności z normami UE i inne ważne certyfikaty związane z bezpieczeństwem i środowiskiem.",

    downloads: {
      ATEX: [
        {
          name: "Certyfikat ATEX STREFA 1",
          url: "https://www.iceqube.com/certificates/ATX1682-1%20-%20ATEX%20EU%20TYPE%20Exam%20Certificate%20-%20QPS%2022ATEX5000X.pdf",
        },
        {
          name: "Deklaracja Zgodności UE ATEX STREFA 1",
          url: "https://www.iceqube.com/certificates/Zone%201%20Declaration%20of%20Conformity%20Rev%207.pdf",
        },
        {
          name: "Certyfikat ATEX STREFA 2 - Klimatyzatory",
          url: "https://www.iceqube.com/certificates/QPS%2016ATEX0002X05.pdf",
        },
        {
          name: "Deklaracja Zgodności UE ATEX STREFA 2 - Klimatyzatory",
          url: "https://www.iceqube.com/certificates/EU%20DoC%20for%20Zone%202%20Air%20Conditioners.pdf",
        },
        {
          name: "Certyfikat ATEX STREFA 2 - Wymienniki Ciepła",
          url: "https://www.iceqube.com/certificates/QPS%2019ATEX0001X02.pdf",
        },
        {
          name: "Deklaracja Zgodności UE ATEX STREFA 2 - Wymienniki Ciepła",
          url: "https://www.iceqube.com/certificates/Zone%202%20DECLARATION%20OF%20CONFORMITY%20Heat%20Exchangers.pdf",
        },
      ],
      IECEX: [
        {
          name: "Certyfikat Zgodności IECEX STREFA 1",
          url: "https://www.iceqube.com/certificates/IECEx_QPS_18.0007X_005.pdf",
        },
        {
          name: "Certyfikat Zgodności IECEX STREFA 2 - Klimatyzatory",
          url: "https://www.iceqube.com/certificates/IECEx_QPS_16.0015X_006.pdf",
        },
        {
          name: "Certyfikat Zgodności IECEX STREFA 2 - Wymienniki Ciepła",
          url: "https://www.iceqube.com/certificates/IECEx_QPS_19.0004X_003.pdf",
        },
      ],
      EU_and_Other_Certificates: [
        {
          name: "Deklaracja Zgodności UE (Produkty do obszarów bezpiecznych)",
          url: "https://www.iceqube.com/certificates/CE%20Declaration%20of%20Conformity-3-8-21.pdf",
        },
        {
          name: "Oświadczenie dotyczące Elektrycznego i Elektronicznego Sprzętu (WEEE)",
          url: "https://www.iceqube.com/wp-content/uploads/2022/04/Ice-Qube-Incs-WEEE-Statement.pdf",
        },
        {
          name: "Deklaracja REACH",
          url: "https://www.iceqube.com/wp-content/uploads/2023/08/REACH-2023.pdf",
        },
        {
          name: "Certyfikat Zgodności RoHS",
          url: "https://www.iceqube.com/wp-content/uploads/2019/06/Ice-Qube-Inc-RoHS-Certificate-of-Compliance-6-12-19.pdf",
        },
        {
          name: "Deklaracja Zgodności stali nierdzewnej 316L",
          url: "https://www.iceqube.com/wp-content/uploads/2022/04/IQ_Inc_Declaration_of_Conformity_316L.pdf",
        },
      ],
      Zone_1: [
        {
          name: "Certyfikat ATEX STREFA 1",
          url: "https://www.iceqube.com/certificates/ATX1682-1%20-%20ATEX%20EU%20TYPE%20Exam%20Certificate%20-%20QPS%2022ATEX5000X.pdf",
        },
        {
          name: "Deklaracja Zgodności UE ATEX STREFA 1",
          url: "https://www.iceqube.com/certificates/Zone%201%20Declaration%20of%20Conformity%20Rev%207.pdf",
        },
        {
          name: "Certyfikat Zgodności IECEX STREFA 1",
          url: "https://www.iceqube.com/certificates/IECEx_QPS_18.0007X_005.pdf",
        },
      ],

      Zone_2: [
        {
          name: "Certyfikat ATEX STREFA 2 - Klimatyzatory",
          url: "https://www.iceqube.com/certificates/QPS%2016ATEX0002X05.pdf",
        },
        {
          name: "Deklaracja Zgodności UE ATEX STREFA 2 - Klimatyzatory",
          url: "https://www.iceqube.com/certificates/EU%20DoC%20for%20Zone%202%20Air%20Conditioners.pdf",
        },
        {
          name: "Certyfikat ATEX STREFA 2 - Wymienniki Ciepła",
          url: "https://www.iceqube.com/certificates/QPS%2019ATEX0001X02.pdf",
        },
        {
          name: "Deklaracja Zgodności UE ATEX STREFA 2 - Wymienniki Ciepła",
          url: "https://www.iceqube.com/certificates/Zone%202%20DECLARATION%20OF%20CONFORMITY%20Heat%20Exchangers.pdf",
        },
        {
          name: "Certyfikat Zgodności IECEX STREFA 2 - Klimatyzatory",
          url: "https://www.iceqube.com/certificates/IECEx_QPS_16.0015X_006.pdf",
        },
        {
          name: "Certyfikat Zgodności IECEX STREFA 2 - Wymienniki Ciepła",
          url: "https://www.iceqube.com/certificates/IECEx_QPS_19.0004X_003.pdf",
        },
      ],
    },
  },
};
